import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ApiService } from '../../services/api.service';
import { ButlerUnitedVertical } from 'src/app/models/ButlerUnitedVertical';
import { LegalService } from 'src/app/services/legal.service';
import { LegalDocumentType } from 'src/app/models/LegalDocumentType';
import {ComplianceRegion} from '../../models/ComplianceRegion';

@Component({
  selector: 'app-legal-document',
  templateUrl: './legal-document.component.html',
  styleUrls: ['./legal-document.component.scss']
})
export class LegalDocumentComponent implements OnInit {
  dispatcherTermsUrl: string;
  verticalBaseUrl: string;
  complianceRegion: string;

  url: string;
  urlSafe: SafeResourceUrl | undefined;

  constructor(public sanitizer: DomSanitizer,
              private apiService: ApiService,
              private legalService: LegalService) {
  }

  ngOnInit() {
    this.apiService.butlerConfiguration$.subscribe(conf => {
      if (conf) {
        this.dispatcherTermsUrl = conf?.companyDetails.legalLink!;
        this.verticalBaseUrl = this.getVerticalBaseUrl(conf?.companyDetails.vertical);
        this.complianceRegion = this.getComplianceRegion(conf?.companyDetails.complianceRegion);
      }
    });
    this.legalService.legalDocumentType$.subscribe(documentType => {
      this.openDocument(documentType);
    });
  }

  openDocument(documentType: LegalDocumentType) {
    switch (documentType) {
      case LegalDocumentType.DISPATCHER_TERMS:
        this.url = this.dispatcherTermsUrl;
        break;
      case LegalDocumentType.TERMS_OF_USE:
        this.url = this.verticalBaseUrl + 'passengers/terms/?region=' + this.complianceRegion + '&doc=tou';
        break;
      case LegalDocumentType.PRIVACY_POLICY:
        this.url = this.verticalBaseUrl + 'passengers/terms/?region=' + this.complianceRegion + '&doc=pp';
      break;
    }
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  getComplianceRegion(complianceRegion: ComplianceRegion): string{
    switch (complianceRegion) {
      case ComplianceRegion.EU:
        return 'EU';
      case ComplianceRegion.UK:
        return 'UK';
      default:
        return 'US';
    }
  }

  getVerticalBaseUrl(vertical: ButlerUnitedVertical): string{
    switch(vertical) {
      case ButlerUnitedVertical.VENUE_BUTLER:
        return 'https://venuebutler.com/';
      case ButlerUnitedVertical.TAXI_BUTLER:
        return 'https://taxibutler.com/';
    }
  }
}
